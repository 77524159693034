import React, {
  useState,
  useCallback,
  useEffect,
  lazy,
  ReactChild,
  Suspense,
} from "react";
import {
  Close,
  AccountCircle,
  CheckCircle,
  DeleteForever,
  FlashOn,
  ViewList,
  Mail,
  Visibility,
  TextSnippet,
  NoAccounts,
  Chat,
} from "@mui/icons-material";
import {
  Button,
  Dropdown,
  Banner,
  ContentCard,
  AccordionLoader,
} from "components";
import {
  useGetMemberLazyQuery,
  useResyncStripeMemberMutation,
  useUpdateMemberMutation,
  useCreateStripeCustomerMutation,
  useUpdateMemberAuthMutation,
  useUpdateMemberNoteMutation,
} from "features/members";
import {
  useResolvedPath,
  useParams,
  useNavigate,
  Outlet,
} from "react-router-dom";
import useSubmit from "hooks/useSubmit";
import { isObjectEqual } from "helpers/isObjectEqual";
import SuspenseWrapper from "components/SuspenseWrapper/SuspenseWrapper";
import { TabItem, TabNav, Tabs } from "components/Tabs";
import TextComboLoading from "components/TextCombo/TextCombo.loading";
import InputLoading from "components/Input/Input.loading";
import { MemberContext } from "./context";
import MemberPaneLoading from "./MembersPane.loading";
import { useMembersContext } from "../context/MembersContextProvider";
import DeleteMemberModal from "../modals/DeleteMemberModal";
import ResetPasswordModal from "../modals/ResetPasswordModal";
import LoginAsMemberModal from "../modals/LoginAsMemberModal";

const StripLogo = lazy(() => import("assets/icons/stripe-logo.svg"));

const Container = ({
  isOpen,
  children,
}: {
  isOpen: boolean;
  children: ReactChild;
}) => (
  <ContentCard
    className={`flex flex-col h-full overflow-hidden bg-white transition-all ${isOpen ? "w-[440px] mr-3 border min-w-[440px]" : "w-0 mr-0 border-none min-w-0"}`}
  >
    {children}
  </ContentCard>
);

interface MemberPaneProps {
  // memberId: Partial<Member>["id"];
  closeDialog: () => void;
  isSandBoxMode: boolean;
  isOpen?: boolean;
}

const MemberPane = ({
  closeDialog,
  isSandBoxMode,
  isOpen,
}: MemberPaneProps) => {
  const navigate = useNavigate();
  const url = useResolvedPath("").pathname;
  const { memberId, appId } = useParams<{ memberId: string; appId: string }>();

  const [showNotes, setShowNotes] = useState<boolean>(false);
  const [showJSONModal, setShowJSONModal] = useState(false);
  const [showAddCFModal, setShowAddCFModal] = useState(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [showMetadataModal, setShowMetadataModal] = useState(false);
  const [showDeleteMemberModal, setShowDeleteMemberModal] = useState(false);
  const [showLoginAsMemberModal, setShowLoginAsMemberModal] = useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);

  const { refetch: refetchMembers } = useMembersContext();

  const [updateMember, { loading: updatingMember }] = useUpdateMemberMutation();
  const [updateMemberAuth, { loading: updatingMemberAuth }] =
    useUpdateMemberAuthMutation();
  const [updateMemberNote, { loading: updatingMemberNote }] =
    useUpdateMemberNoteMutation();
  const [resyncStripeMember, { loading: syncingStrip }] =
    useResyncStripeMemberMutation();
  const [createStripeCustomer, { loading: creatingStripe }] =
    useCreateStripeCustomerMutation();

  const isProfileTab = window.location.pathname.split("/").includes("profile");

  const [getMember, { loading, data, error, refetch }] =
    useGetMemberLazyQuery();

  useEffect(() => {
    if (Boolean(memberId) && isOpen) {
      getMember({
        variables: { id: memberId },
      });
    }
  }, [getMember, isOpen, memberId]);

  const { submit: handleResyncStripeMember } = useSubmit({
    action: resyncStripeMember,
    fields: {
      memberId,
    },
    success:
      "Syncing member in progress - it may take up to 5 minutes to complete.",
  });

  const { submit: handleVerifyMember } = useSubmit({
    action: updateMember,
    fields: {
      memberId,
      verified: true,
    },
    refetch,
    success: "Member has been successfully verified.",
  });

  const { submit: handleUpdateMember } = useSubmit({
    action: updateMember,
    refetch: () => {
      refetchMembers();
      refetch();
    },
    success: "Member profile was successfully updated.",
  });

  const { submit: handleUpdateMemberAuth } = useSubmit({
    action: updateMemberAuth,
    refetch: () => {
      refetchMembers();
      refetch();
    },
    success: "Member's email was successfully updated.",
  });

  const { submit: handleUpdateMemberNotes } = useSubmit({
    action: updateMemberNote,
    refetch: () => {
      refetchMembers();
      refetch();
    },
    success: "Member's note was successfully updated.",
  });

  const handleShowModal = useCallback(
    (modalType: "deleteMember" | "resetPassword" | "loginAsMember") => () => {
      switch (modalType) {
        case "deleteMember":
          return setShowDeleteMemberModal(true);
        case "resetPassword":
          return setShowResetPasswordModal(true);
        default:
          return setShowLoginAsMemberModal(true);
      }
    },
    []
  );

  const { submit: handleCreateStripeCustomer } = useSubmit({
    action: createStripeCustomer,
    fields: {
      memberId,
    },
    success: "Stripe Customer Successfully Created.",
    refetch,
  });

  useEffect(() => {
    if (!data?.currentMember?.memberNotes?.note) {
      setShowNotes(false);
    }
    // close notes when member is changed
    return () => setShowNotes(false);
  }, [data?.currentMember?.memberNotes?.note, memberId]);

  if (loading || data === undefined) {
    return (
      <Container isOpen={isOpen}>
        <MemberPaneLoading
          isSandBoxMode={isSandBoxMode}
          closeDialog={closeDialog}
        />
      </Container>
    );
  }

  if (error || !data?.currentMember) {
    return (
      <Container isOpen={isOpen}>
        <div className="my-5 ml-4 mr-5" aria-label="member-pane">
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <Button
                buttonStyle="icon"
                // onClick={onClose}
                rightIcon={<Close />}
                dataCy="member-pane-close-button"
              />
              <h4 className="ml-1 text-body-sm truncate w-52">
                User Not Found
              </h4>
            </div>
          </div>
          <div className="flex justify-center items-center">
            <NoAccounts tw="h-10! w-10!" />
            <p>This user does not exist on this app</p>
          </div>
        </div>
      </Container>
    );
  }

  const member = data?.currentMember;

  const {
    auth,
    createdAt,
    id,
    planConnections,
    customFields,
    permissions,
    metaData,
    json,
    loginRedirect,
    stripeCustomerId,
    verified,
    memberNotes,
    profileImage,
    isModerator,
    trustStatus,
  } = member || {};

  const tabs = [
    {
      Icon: <AccountCircle />,
      label: "Profile",
      url: `${url}/${memberId}/profile`,
      value: "profile",
    },
    {
      Icon: <FlashOn />,
      label: "Plans & Permissions",
      url: `${url}/${memberId}/connections`,
      value: "connections",
    },
    {
      Icon: <Chat />,
      label: "Comments",
      url: `${url}/${memberId}/comments/ALL`,
      value: "comments",
    },
  ];

  const options = [
    {
      Icon: <Visibility />,
      text: "Login as Member",
      onClick: handleShowModal("loginAsMember"),
      dataCy: "login-as-member-option",
    },
    {
      Icon: <Mail />,
      text: "Reset Password",
      onClick: handleShowModal("resetPassword"),
      dataCy: "reset-password-option",
    },
    {
      Icon: <ViewList />,
      text: "View Event Log",
      onClick: () => navigate(`/apps/${appId}/event-log?member_id=${id}`),
      dataCy: "view-event-log-option",
    },
    {
      Icon: <TextSnippet className="text-yellow-500" />,
      text: "Add Internal Note",
      onClick: () => setShowNotes(true),
      dataCy: "add-internal-note-option",
    },
    verified
      ? null
      : {
          Icon: <CheckCircle className="text-app-green500" />,
          text: "Verify Email",
          onClick: () => handleVerifyMember(),
          dataCy: "verify-email-option",
        },
    {
      Icon: (
        <SuspenseWrapper>
          <StripLogo />
        </SuspenseWrapper>
      ),
      text: stripeCustomerId ? "Resync with Stripe" : "Create Stripe Customer",
      onClick: () =>
        stripeCustomerId
          ? handleResyncStripeMember()
          : handleCreateStripeCustomer(),
      dataCy: stripeCustomerId
        ? "resync-with-stripe-option"
        : "create-stripe-customer-option",
    },
    {
      isDangerText: true,
      Icon: <DeleteForever />,
      text: "Delete Member",
      onClick: handleShowModal("deleteMember"),
      dataCy: "delete-member-option",
    },
  ].filter((option) => option !== null);

  // define saveActions here to avoid circular dependency
  const saveActions = {
    customFields: undefined,
    loginRedirectInput: undefined,
    email: undefined,
    note: undefined,
    trustStatus: undefined,
    isModerator: false,
  };

  return (
    <Container isOpen={isOpen}>
      {isOpen && (
        <MemberContext.Provider
          value={{
            metaData,
            json,
            loginRedirect,
            memberEmail: auth?.email,
            connectedProviders: auth?.connectedProviders,
            memberId: id,
            memberPlanConnections: planConnections,
            memberCustomFields: customFields,
            // @ts-ignore
            memberPermissions: permissions,
            memberRefetch: refetch,
            createdAt,
            stripeCustomerId,
            setIsDisabled,
            verified,
            memberNotes,
            showNotes,
            profileImage,
            isModerator,
            showJSONModal,
            showMetadataModal,
            showAddCFModal,
            setShowJSONModal,
            setShowMetadataModal,
            setShowAddCFModal,
            saveActions,
            trustStatus,
          }}
        >
          <div className="my-5 ml-4 mr-5" aria-label="member-pane">
            <div className="flex items-center justify-between mb-2">
              <div className="flex items-center">
                <Button
                  buttonStyle="icon"
                  onClick={() => navigate(`/apps/${appId}/members`)}
                  rightIcon={<Close />}
                  dataCy="member-pane-close-button"
                />
                <h4 className="ml-1 text-body-sm truncate w-52">
                  {auth?.email}
                </h4>
              </div>
              <div className="flex items-center">
                {isProfileTab && (
                  <Button
                    tw="mx-2"
                    text="Save"
                    buttonStyle="default"
                    onClick={() => {
                      const {
                        loginRedirectInput,
                        customFields: _customFields,
                        email,
                        note,
                        trustStatus: trustStatusInput,
                        isModerator: isModeratorInput,
                      } = saveActions;
                      if (
                        loginRedirectInput ||
                        _customFields ||
                        trustStatusInput ||
                        isModeratorInput !== isModerator
                      ) {
                        handleUpdateMember({
                          memberId,
                          ...(loginRedirect !== loginRedirectInput && {
                            loginRedirect: loginRedirectInput,
                          }),
                          ...(!isObjectEqual(_customFields, customFields) && {
                            customFields: _customFields,
                          }),
                          trustStatus: trustStatusInput,
                          isModerator: isModeratorInput,
                        });
                      }

                      if (email) {
                        handleUpdateMemberAuth({
                          memberId,
                          email,
                        });
                      }

                      if (note) {
                        handleUpdateMemberNotes({
                          memberId,
                          note,
                        });
                      }
                    }}
                    isLoading={
                      updatingMember ||
                      updatingMemberAuth ||
                      updatingMemberNote ||
                      syncingStrip ||
                      creatingStripe
                    }
                    isDisabled={isDisabled}
                    dataCy="member-pane-save-button"
                  />
                )}
                <Dropdown
                  options={options}
                  dataCy="member-pane-actions-dropdown"
                  hasLastChildDivider
                  optionsTextAlign="left"
                  targetElement={() => (
                    <Button text="Actions" buttonStyle="skeleton" />
                  )}
                />
              </div>
            </div>
            {isSandBoxMode && (
              <Banner text="Editing a test member." className="mt-4" />
            )}
          </div>
          <Tabs isExpanded showBottomBorder>
            <TabNav>
              {tabs.map((tab) => (
                <TabItem
                  key={tab.label}
                  id={tab.value}
                  label={tab.label}
                  Icon={tab.Icon}
                  url={tab.url}
                />
              ))}
            </TabNav>
            <Suspense
              fallback={
                <>
                  <div className="pt-5 px-5">
                    <TextComboLoading hasLabel spaceOut className="mb-6" />
                    <TextComboLoading hasLabel className="mb-6" />
                    <InputLoading className="mb-6" />
                  </div>
                  <AccordionLoader />
                </>
              }
            >
              <Outlet />
            </Suspense>
          </Tabs>
          <DeleteMemberModal
            showModal={showDeleteMemberModal}
            setShowModal={setShowDeleteMemberModal}
            memberId={memberId}
            memberEmail={auth?.email}
          />
          <ResetPasswordModal
            memberId={memberId}
            memberEmail={auth?.email}
            showModal={showResetPasswordModal}
            setShowModal={setShowResetPasswordModal}
          />
          <LoginAsMemberModal
            memberId={memberId}
            setShowModal={setShowLoginAsMemberModal}
            showModal={showLoginAsMemberModal}
          />
        </MemberContext.Provider>
      )}
    </Container>
  );
};

export default MemberPane;

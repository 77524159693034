import React from "react";
import CodeMirror, { type ReactCodeMirrorProps } from "@uiw/react-codemirror";
import styled, { css } from "styled-components";
import { CopyButton } from "../Button";
import cn from "helpers/cn";

interface Props extends ReactCodeMirrorProps {
  className?: string;
  copyMessage?: string;
  enableCopy?: boolean;
  hasBorder?: boolean;
  showLineNumbers?: boolean;
  enableFoldGutter?: boolean;
  isPulsingCopy?: boolean;
  onCopyClick?: () => void;
  description?: React.ReactNode;
  isCustom?: boolean;
}

const StyledCodeBlock = styled(CodeMirror)<{
  isBold?: boolean;
  $hasBorder?: boolean;
  $isWhiteBackground?: boolean;
}>`
  ${({ theme: { colors }, isBold, $hasBorder, $isWhiteBackground }) => css`
    position: relative;
    border: ${$hasBorder ? `1px solid ${colors.grey200}` : "none"};
    border-radius: ${$hasBorder ? "8px" : "none"};
    & > .cm-editor,
    .cm-scroller {
      font-size: 13px;
      line-height: 16px;
      border-radius: ${$hasBorder ? "8px" : "none"};
      & > .cm-content {
        padding: 12px 0;
      }
    }
    /* & > div {
      border-radius: 8px;
      background-color: ${$isWhiteBackground ? "white" : colors.grey100};
      font-size: 14px;
      line-height: 24px;
      font-weight: ${isBold ? 700 : 500};
      border: 1px solid ${colors.grey200};
    } */
  `}
`;

export const CodeBlock = ({
  value,
  height,
  minHeight,
  maxHeight,
  autoFocus,
  placeholder,
  extensions,
  editable,
  readOnly,
  className,
  onChange,
  onCopyClick,
  copyMessage,
  enableCopy = true,
  isPulsingCopy = false,
  hasBorder = true,
  showLineNumbers = true,
  enableFoldGutter = false,
  description,
  isCustom = false,
}: Props) => {
  const [isFocused, setIsFocused] = React.useState(false);

  return (
    <div className="relative">
      {isCustom ? (
        <pre className="p-2 bg-white rounded-md border border-app-gray200">
          <code className="text-wrap break-words">{value}</code>
        </pre>
      ) : (
        <StyledCodeBlock
          className={className}
          $hasBorder={hasBorder}
          extensions={extensions}
          value={value}
          placeholder={placeholder}
          readOnly={readOnly}
          height={height}
          minHeight="34px"
          maxHeight={maxHeight}
          autoFocus={autoFocus}
          editable={editable}
          onChange={onChange}
          basicSetup={{
            lineNumbers: showLineNumbers,
            foldGutter: enableFoldGutter,
            searchKeymap: false,
          }}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
      )}
      {enableCopy && (
        <CopyButton
          value={value}
          message={copyMessage}
          text="Copy"
          tw="absolute top-2 right-2"
          inverted
          isPulsing={isPulsingCopy}
          onClick={onCopyClick}
        />
      )}
      {description && (
        <div
          className={cn(
            "text-sm cursor-default transition-all text-app-gray600",
            `${isFocused ? "translate-y-0 h-full opacity-100" : "translate-y-0 h-0 opacity-0"}`
          )}
        >
          {description}
        </div>
      )}
    </div>
  );
};

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import SettingsBar, { TitleSection } from "components/SettingsBar";
import Input from "components/Input";
import {
  AttributeInstallationBox,
  Background,
  Button,
  Switch,
  MarkdownEditor,
  Card,
  Link,
  ContentWrapper,
} from "components";
import { useAppDataContext } from "contexts/AppDataContext";
import { useUpdateAppMutation, useUpdateEmailsMutation } from "features";
import { useToastAlertContext } from "contexts/ToastAlertContext";
import { isError } from "helpers/typeguards";
import {
  useGetAppRedirectsQuery,
  useUpdateDefaultRedirectsMutation,
} from "features/redirects";
import { useGetEmailsQuery } from "features/emails";
import { getObjectWithKeys } from "helpers/getObjectWithKeys";
import { isObjectEqual } from "helpers/isObjectEqual";
import { removeEmpty } from "helpers/removeEmpty";
import { trimObjectStringValues } from "helpers/trimObjectStringValues";
import { useGetBrandingQuery } from "features/branding";

import {
  DEFAULT_BUTTON_TEXT,
  DEFAULT_INTRO,
  DEFAULT_OUTRO,
  INITIAL_VERIFICATION_EMAIL_STATE,
  useHandleOnChange,
} from "../emails.utils";
import { EmailTemplateLoading } from "../emails.loading";
import * as S from "../emails.styles";

export const VerificationEmail = () => {
  const { appData, refetch: refetchApp, isLoading } = useAppDataContext();
  const [updateApp, { loading: appDataLoading }] = useUpdateAppMutation();

  const [verificationRequired, setVerificationRequired] = useState("");
  const [emailVerificationEnabled, setEmailVerificationEnabled] =
    useState(false);
  const [requireEmailVerification, setRequireEmailVerification] =
    useState(false);
  const [
    sendWelcomeEmailAfterVerification,
    setSendWelcomeEmailAfterVerification,
  ] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<{ [key: string]: string }>(
    INITIAL_VERIFICATION_EMAIL_STATE
  );
  const [isFormValid, setIsFormValid] = useState(false);
  const valRef = useRef(null);

  const { createToastAlert } = useToastAlertContext();

  const [updateEmails, { loading }] = useUpdateEmailsMutation();
  const [updateDefaultRedirects, { loading: defaultRedirectLoading }] =
    useUpdateDefaultRedirectsMutation();

  const { data: branding } = useGetBrandingQuery();

  const {
    data: emails,
    loading: loadingEmails,
    refetch: refetchEmails,
  } = useGetEmailsQuery();
  const {
    data: appRedirects,
    loading: loadingAppRedirects,
    refetch: refetchAppRedirects,
  } = useGetAppRedirectsQuery();

  const emailVerification = useMemo(
    () =>
      getObjectWithKeys(
        emails?.getEmails?.emailVerification || {},
        Object.keys(INITIAL_VERIFICATION_EMAIL_STATE)
      ),
    [emails?.getEmails?.emailVerification]
  );

  useEffect(() => {
    valRef.current = emailVerification;
    setFormValues({
      replyTo: valRef.current?.replyTo || "",
      subject: valRef.current?.subject || "",
      paragraph1: valRef.current?.paragraph1 || "",
      paragraph2: valRef.current?.paragraph2 || "",
      paragraph3: valRef.current?.paragraph3 || "",
      primaryButtonText: valRef.current?.primaryButtonText || "",
    });
    setEmailVerificationEnabled(emails?.getEmails?.emailVerificationEnabled);
    setRequireEmailVerification(emails?.getEmails?.requireEmailVerification);
    setVerificationRequired(appRedirects?.getRedirects?.verificationRequired);
    setSendWelcomeEmailAfterVerification(
      appData?.sendWelcomeEmailAfterVerification
    );
  }, [
    appRedirects?.getRedirects?.verificationRequired,
    emails?.getEmails?.emailVerificationEnabled,
    emails?.getEmails?.requireEmailVerification,
    appData?.sendWelcomeEmailAfterVerification,
    emailVerification,
  ]);

  const { handleOnChange } = useHandleOnChange({ setFormValues, formValues });

  const validateForm = useCallback(() => {
    const isValid = formValues.replyTo.trim() !== "";
    setIsFormValid(isValid);
    return isValid;
  }, [formValues.replyTo]);

  useEffect(() => {
    validateForm();
  }, [formValues.replyTo, validateForm]);

  // check if emailVerificationEnabled changed, then run mutation
  const didEmailVerificationChange =
    emails?.getEmails?.emailVerificationEnabled !== emailVerificationEnabled;

  // check if requireEmailVerification changed, then run mutation
  const didRequireEmailVerificationChanged =
    emails?.getEmails?.requireEmailVerification !== requireEmailVerification;

  const didSendWelcomeEmailAfterVerificationChanged =
    appData?.sendWelcomeEmailAfterVerification !==
    sendWelcomeEmailAfterVerification;

  // check if verificationRequired changed, then run mutation
  const didVerificationRequiredChanged =
    appRedirects?.getRedirects?.verificationRequired !== verificationRequired;

  // check if email form changed then run mutation
  const didEmailFormChange = !isObjectEqual(
    removeEmpty(valRef.current || {}),
    removeEmpty(trimObjectStringValues(formValues))
  );

  const didFormChange =
    didEmailFormChange ||
    didEmailVerificationChange ||
    didRequireEmailVerificationChanged ||
    didSendWelcomeEmailAfterVerificationChanged ||
    appRedirects?.getRedirects?.verificationRequired !==
      verificationRequired?.trim();

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      if (!validateForm()) {
        createToastAlert({
          alertType: "error",
          message: "Please fill in the Reply-To-Email field.",
        });
        return;
      }
      createToastAlert({ processing: true });

      try {
        // check if email form changed then run mutation
        if (didEmailFormChange) {
          await updateEmails({
            variables: {
              input: {
                emailVerification: { ...formValues },
              },
            },
          });
        }

        // check if emailVerificationEnabled changed, then run mutation
        if (didEmailVerificationChange) {
          await updateEmails({
            variables: {
              input: {
                emailVerificationEnabled,
              },
            },
          });
        }

        // check if requireEmailVerification changed, then run mutation
        if (didRequireEmailVerificationChanged) {
          await updateEmails({
            variables: {
              input: {
                requireEmailVerification,
              },
            },
          });
        }

        if (didSendWelcomeEmailAfterVerificationChanged) {
          await updateApp({
            variables: {
              input: {
                sendWelcomeEmailAfterVerification,
              },
            },
          });
        }

        // check if verificationRequired changed, then run mutation
        if (didVerificationRequiredChanged) {
          await updateDefaultRedirects({
            variables: {
              input: { verificationRequired },
            },
          });
        }

        createToastAlert({
          alertType: "success",
          message: "Verification email saved!",
        });
      } catch (_error) {
        if (isError(_error)) {
          createToastAlert({
            alertType: "error",
            message: _error.message,
          });
        }
      } finally {
        if (
          didEmailFormChange ||
          didEmailVerificationChange ||
          didRequireEmailVerificationChanged
        ) {
          await refetchEmails();
        }

        if (didSendWelcomeEmailAfterVerificationChanged) {
          await refetchApp();
        }

        if (didVerificationRequiredChanged) {
          await refetchAppRedirects();
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      didEmailFormChange,
      didEmailVerificationChange,
      didRequireEmailVerificationChanged,
      didVerificationRequiredChanged,
      didSendWelcomeEmailAfterVerificationChanged,
      formValues,
      emailVerificationEnabled,
      requireEmailVerification,
      sendWelcomeEmailAfterVerification,
      verificationRequired,
    ]
  );

  if (loadingEmails || loadingAppRedirects || isLoading || appDataLoading)
    return <EmailTemplateLoading title="Email Verification" />;

  return (
    <ContentWrapper>
      <SettingsBar>
        <TitleSection
          title="Email Verification"
          titleHint="Enable & personalize verification emails."
          backLink="../emails"
          backLinkTitle="Emails"
          emphasize
          gutter="medium"
          buttons={
            <Button
              text="Save"
              onClick={onSubmit}
              isLoading={loading || defaultRedirectLoading}
              isDisabled={!didFormChange || !isFormValid}
            />
          }
        />
        <form
          className="p-5 overflow-y-auto flex flex-col h-full"
          onSubmit={onSubmit}
        >
          <Switch
            tw="pb-5"
            label="Enable Email Verification"
            isChecked={emailVerificationEnabled}
            name="emailVerificationEnabled"
            id="emailVerificationEnabled"
            onChange={() =>
              setEmailVerificationEnabled(!emailVerificationEnabled)
            }
          />
          {emailVerificationEnabled && (
            <>
              <Input
                name="replyTo"
                placeholder="email@somemail.com"
                value={formValues.replyTo}
                label="Reply-To-Email"
                infoHintText="Email address"
                tw="pb-5"
                required
                description="Enter active support email (no 'noreply' addresses)"
                onChange={({ target: { value } }) =>
                  handleOnChange({ type: "replyTo", value })
                }
                onBlur={validateForm}
              />
              <Input
                name="subject"
                placeholder="Verify your email"
                value={formValues.subject}
                label="Subject"
                infoHintText="Subject of the email"
                tw="pb-5"
                description="Customize the email subject."
                onChange={({ target: { value } }) =>
                  handleOnChange({ type: "subject", value })
                }
              />
              <MarkdownEditor
                label="Intro"
                name="paragraph1"
                height={150}
                className="mb-5"
                value={formValues.paragraph1}
                placeholder={DEFAULT_INTRO}
                description="Customize the body of your email. Markdown supported."
                onChange={(value) =>
                  handleOnChange({ type: "paragraph1", value })
                }
              />
              <Input
                name="primaryButtonText"
                placeholder={DEFAULT_BUTTON_TEXT}
                value={formValues.primaryButtonText}
                label="Button Text"
                tw="pb-5"
                description="Customize the button text."
                onChange={({ target: { value } }) =>
                  handleOnChange({ type: "primaryButtonText", value })
                }
              />
              <MarkdownEditor
                label="Outro"
                name="paragraph2"
                height={90}
                className="mb-5"
                value={formValues.paragraph2}
                placeholder={DEFAULT_OUTRO}
                description="Customize the body of your email. Markdown supported."
                onChange={(value) =>
                  handleOnChange({ type: "paragraph2", value })
                }
              />
              <Switch
                tw="pb-5"
                label="Require verification to complete signup."
                isChecked={requireEmailVerification}
                name="require-for-signup"
                id="require-for-signup"
                onChange={() =>
                  setRequireEmailVerification(!requireEmailVerification)
                }
              />
              <Switch
                tw="pb-5"
                label="Send Welcome Email After Verification"
                isChecked={sendWelcomeEmailAfterVerification}
                name="require-for-welcome-email"
                id="require-for-welcome-email"
                onChange={() =>
                  setSendWelcomeEmailAfterVerification(
                    !sendWelcomeEmailAfterVerification
                  )
                }
                description={
                  <span>
                    By default, the welcome email is sent before verification.
                    Enable this toggle to send it after email verification.{" "}
                    <Link
                      isExternal
                      underline
                      to="https://docs.memberstack.com/hc/en-us/articles/17975907632539-Welcome-Emails"
                    >
                      Learn more.
                    </Link>
                  </span>
                }
              />
              {requireEmailVerification && (
                <Input
                  tw="pb-5"
                  hideIndicators
                  label="If the member is not verified, send them to the following page on signup, on login, and when they try to access gated content."
                  placeholder="premium"
                  value={verificationRequired}
                  onChange={({ target }) =>
                    setVerificationRequired(target.value)
                  }
                  leading="yourdomain.com/"
                />
              )}
            </>
          )}
          <div tw="flex-1" />
          <AttributeInstallationBox
            isLinkOnly
            docsLink="https://docs.memberstack.com/hc/en-us/articles/8437630733083-Email-Verification"
          />
        </form>
      </SettingsBar>
      <Background>
        <div className="p-5 max-h-full min-h-0 overflow-auto">
          <S.UploadLogoText />
          <S.ShowcaseCard>
            {appData?.image && (
              <div tw="pb-[30px]">
                <img
                  src={appData?.image}
                  height={43}
                  tw="h-[43px]"
                  alt={appData?.name}
                />
              </div>
            )}
            <MarkdownEditor
              readonly
              value={formValues.paragraph1 || DEFAULT_INTRO}
            />
            <Button
              text={formValues.primaryButtonText || DEFAULT_BUTTON_TEXT}
              tw="my-[30px]"
              style={{
                backgroundColor:
                  branding?.getBranding?.colors?.light.primaryButton,
              }}
            />
            <MarkdownEditor
              readonly
              value={formValues.paragraph2 || DEFAULT_OUTRO}
            />
          </S.ShowcaseCard>
        </div>
      </Background>
    </ContentWrapper>
  );
};

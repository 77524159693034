import React from "react";
import { Accordion } from "components";
import GatedContentAccordion from "./GatedContentAccordion";
import DefaultRedirects from "./DefaultRedirects";

const DefaultSettings = () => {
  return (
    <div className="w-1/2 flex flex-col overflow-y-auto">
      <div className="p-5 flex flex-col">
        <div className="flex items-center justify-between">
          <div className="flex items-center text-base gap-2">
            Default Settings
          </div>
        </div>
      </div>
      <div className="flex-1 overflow-y-auto h-screen">
        <Accordion name="default-redirect" defaultExpanded="redirects">
          <DefaultRedirects />
          <GatedContentAccordion />
        </Accordion>
      </div>
    </div>
  );
};

export default DefaultSettings;

import "react-loading-skeleton/dist/skeleton.css";
import React, { useEffect, useMemo, useState } from "react";
import mergeClassNames from "helpers/mergeClassNames/mergeClassNames";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Banner, Button, Dropdown, Link, Table } from "components";
import { HorizontalRule } from "@mui/icons-material";
import SearchBar from "components/SearchBar";
import { useEnvContext } from "containers/App/contexts/Environment";
import { useTable, useRowSelect } from "react-table";
import { useCustomFieldContext } from "features/custom-fields";
import NoSearchResult from "components/SearchBar/NoSearchResult";
import cn from "helpers/cn";
import {
  DEFAULT_COLUMNS_KEYS,
  determineColumnClassName,
  useGetTableData,
} from "../../Members.utils";
import { IndeterminateCheckbox } from "./IndeterminateCheckbox";
import { useMembersContext } from "../../context/MembersContextProvider";
import DeleteSelectMemberModal from "../../modals/DeleteSelectMemberModal";

const MembersTable = ({
  activeMemberId,
  options,
  onNavigate,
  filterOptions,
  changeHandler,
  searchValue,
}) => {
  const [hasMore, setHasMore] = useState(true);
  const { isSandboxEnv } = useEnvContext();
  const [membersToDelete, setMembersToDelete] = useState([""]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { customFields } = useCustomFieldContext();
  const { refetch, members, fetchMore, endCursor } = useMembersContext();

  useEffect(() => {
    setHasMore(members.length >= 50);
  }, [members.length]);

  const { columns, data } = useGetTableData({ members, customFields });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    toggleAllRowsSelected,
    toggleRowSelected,
    // allColumns,
    // setColumnOrder,
  } = useTable(
    {
      columns,
      data,
    },
    // useColumnOrder,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((_columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          key: "selection",
          Cell: ({ row }) => (
            <IndeterminateCheckbox
              id={row.id}
              {...row.getToggleRowSelectedProps()}
              toggleRowSelected={toggleRowSelected}
              dataCy="select-member-checkbox"
            />
          ),
        },
        ..._columns,
      ]);
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) as any;

  const selectedRows = useMemo(
    () => selectedFlatRows.map((d) => d.original.id),
    [selectedFlatRows]
  );

  const fetchMoreData = async () => {
    if (hasMore) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { data: _data }: any = await fetchMore({
        variables: {
          after: endCursor,
        },
      });

      if (
        _data &&
        _data.getMembers &&
        _data.getMembers.edges &&
        _data?.getMembers?.edges?.length === 0
      ) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
    }
    return null;
  };

  return (
    <>
      <div className="flex gap-2 items-center mx-5">
        <SearchBar
          value={searchValue}
          onChange={changeHandler}
          placeholder="Search by email, custom fields (case-sensitive), or member ID"
          filterOptions={filterOptions}
          className="w-full"
        />
        {selectedRows.length > 0 && (
          <>
            <Button
              text="Deselect"
              buttonStyle="transparent"
              tw="w-[fit-content] overflow-visible"
              onClick={() => toggleAllRowsSelected(false)}
            />
            <Button
              text={`Delete ${selectedRows.length} Member(s)`}
              onClick={() => {
                setShowDeleteModal(true);
                setMembersToDelete(selectedRows);
              }}
              buttonStyle="danger"
              tw="w-[fit-content] overflow-visible"
              // disable button when row selection is more than 100
              isDisabled={selectedRows.length > 100}
              dataCy="delete-selected-members-button"
            />
          </>
        )}
      </div>

      <>
        {isSandboxEnv && (
          <Banner
            className="mt-4 mx-5"
            text="You’re viewing and editing Test Mode members"
            description="Test Mode members cannot be converted to Live Mode members."
            rightPlugin={
              <Link
                to="https://docs.memberstack.com/hc/en-us/articles/7595262385819-Managing-Test-Mode-and-Live-Mode"
                target="_blank"
                isBold
                isExternal
              >
                Learn more
              </Link>
            }
          />
        )}

        {rows.length > 0 ? (
          <Table
            className="pt-5"
            fetchMoreData={fetchMoreData}
            hasMoreData={hasMore}
            numberOfData={rows.length}
            tableProps={getTableProps()}
            headerCellPadding={0}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr
                  data-cy="member-row"
                  key="header"
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column) => (
                    <th
                      key={column.id}
                      {...column.getHeaderProps({
                        className: cn(
                          determineColumnClassName(column.key),
                          "!p-0 !max-w-[300px] truncate"
                        ),
                      })}
                    >
                      {DEFAULT_COLUMNS_KEYS.includes(column.key) ? (
                        <div className="flex p-4 items-center w-full justify-between rounded-lg transition-all">
                          <div className="items-center flex  gap-2">
                            {column.icon}
                            {column.label}
                          </div>
                        </div>
                      ) : (
                        <Dropdown
                          options={options({
                            id: column.customFieldId,
                            label: column.label,
                            key: column.key,
                            visibility: column.visibility,
                          })}
                          targetElement={(isOpen) => (
                            <div
                              className={cn(
                                "flex p-4 items-center w-full justify-between rounded-lg cursor-pointer hover:bg-app-gray50 transition-all",
                                { "bg-app-gray50": isOpen }
                              )}
                            >
                              <div className="items-center flex gap-2">
                                {column.icon}
                                {column.label}
                              </div>
                              <div className="w-4">
                                <MoreVertIcon
                                  className="more-options h-4 w-4 text-black cursor-pointer hidden ml-1"
                                  aria-hidden="true"
                                />
                              </div>
                            </div>
                          )}
                        />
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                const clearActiveMember =
                  activeMemberId && row.original.id === activeMemberId;
                const path = clearActiveMember
                  ? "./"
                  : `${row.original.id}/profile`;
                prepareRow(row);
                return (
                  <tr
                    key={row.id}
                    {...row.getRowProps()}
                    onClick={onNavigate(path)}
                    className={mergeClassNames(
                      activeMemberId === row.original.id && "selected",
                      row.original.isNew && "active"
                    )}
                  >
                    {row.cells.map((cell) => {
                      const key = `${cell.column.id}-${row.id}`;

                      return (
                        <td
                          key={key}
                          data-cy={`members-table-${cell.column.key}`}
                          {...cell.getCellProps({
                            className: cn(
                              determineColumnClassName(cell.column.key),
                              "!max-w-[300px] truncate"
                            ),
                          })}
                        >
                          {cell.column?.key === "plans" &&
                          row.values?.plans?.props?.planConnections?.length ===
                            0 ? (
                            <HorizontalRule />
                          ) : (
                            cell.render("Cell")
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <NoSearchResult onClearSearch={() => changeHandler("")} />
        )}
      </>

      <DeleteSelectMemberModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        membersToDelete={membersToDelete}
        refetch={refetch}
      />
    </>
  );
};

export default MembersTable;
